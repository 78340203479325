import React from "react";
import Iframe from "react-iframe";

const Map = props => {
  return (
    <header className="header-container">
      <div className="map-header">
        <div className="item">
          <img className="image" src={props.htlogo} alt="" />
        </div>

        <div className="item">
          <h4>Adress</h4>
          {props.adress > [0]
            ? props.adress.split("\r").map((item, key) => (
                <span className="span" key={key}>
                  <p className="text">{item}</p>
                  <br />
                </span>
              ))
            : null}
        </div>
        <div className="item">
          <h4>
            {props.lang === "SV"
              ? "Kontakta oss"
              : props.lang === "EN"
              ? "Contact Us"
              : null}
          </h4>
          <span>
            <p className="text">Email: {props.email}</p>
            <br />
          </span>
          <span>
            <p className="text">
              {props.lang === "SV"
                ? "Telefonnummer: "
                : props.lang === "EN"
                ? "Phone number: "
                : null}
              {props.phoneNumber}
            </p>
            <br />
          </span>
        </div>
      </div>
      <div className="map-container">
        <footer className="cover" />
        <Iframe
          className="gmap_canvas"
          overflow="hidden"
          frameBorder="0"
          src="https://maps.google.com/maps?q=roddargatan%2015&t=k&z=13&ie=UTF8&iwloc=&output=embed"
        />
      </div>

      {props.endBlock}
    </header>
  );
};

export default Map;
