import React, { Component } from "react";
import BlockMaker from "./Components/BlockMaker";
import HomePage from "./Components/HomePage";
import Map from "./Components/Map";
import EndBlock from "./Components/EndBlock";
import scrollToComponent from "react-scroll-to-component";
import ScrollUpButton from "react-scroll-up-button";
import topLogo from "./StaticContent/Images/HT_logo_transparent_2k_top.png";
import bottomLogo from "./StaticContent/Images/HT_logo_transparent_2k_bottom.png";
import curtainLeft from "./StaticContent/Images/curtain-left.png";
import curtainRight from "./StaticContent/Images/curtain-right.png";
import curtainTop from "./StaticContent/Images/curtain-top.png";
import svFlag from "./StaticContent/Images/svFlag.svg";
import enFlag from "./StaticContent/Images/enFlag.png";
import Rellax from "react-rellax";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { BrowserView, MobileView } from "react-device-detect";
import Sidebar from "react-sidebar";
import "./Css/App.css";
import "./Css/NoImage.css";
import "./Css/ImageLeft.css";
import "./Css/ImageRight.css";
import "./Css/Header-Container.css";
import "./Css/EndBlock.css";
import "./Css/Posters.css";
import "./Css/MediaQueries.css";

window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

var scrollSpeed;
function adjustSpeed() {
  if (a1.matches) {
    scrollSpeed = -5.7;
  }
  if (a.matches) {
    scrollSpeed = -6.5;
  }
  if (b.matches) {
    scrollSpeed = -4;
  }
  if (c.matches) {
    scrollSpeed = -4.6;
  }

  if (d.matches) {
    scrollSpeed = -5;
  }
  if (e.matches) {
    scrollSpeed = -5.5;
  }
  if (f.matches) {
    scrollSpeed = -5.5;
  }
  if (g.matches) {
    scrollSpeed = -7.5;
  }
  if (h.matches) {
    scrollSpeed = -7;
  }
  if (j.matches) {
    scrollSpeed = -7.5;
  }
  if (j.matches) {
    scrollSpeed = -8;
  }
  if (k.matches) {
    scrollSpeed = -13;
  }
  console.log(scrollSpeed);
}
var a1 = window.matchMedia("(min-width: 0px)");
var a = window.matchMedia("(min-width: 400px)");
var b = window.matchMedia("(min-width: 500px)");
var c = window.matchMedia("(min-width: 780px)");
var d = window.matchMedia("(min-width: 840px)");
var e = window.matchMedia("(min-width: 900px)");
var f = window.matchMedia("(min-width: 1050px)");
var g = window.matchMedia("(min-width: 1400px)");
var h = window.matchMedia("(min-width: 1500px)");
var i = window.matchMedia("(min-width: 1600px)");
var j = window.matchMedia("(min-width: 1800px)");
var k = window.matchMedia("(min-width: 2500px)");
var mediaList = [a, b, c, d, e, f, g, h, i, j, k];
mediaList.forEach(element => {
  adjustSpeed();
  element.addListener(adjustSpeed);
});

class App extends Component {
  state = {
    blocks: [],
    panButtons: [],
    panButtonClicked: false,
    top: true,
    posters: [],
    posterImages: [],
    posterDates: [],
    sidebarOpen: false,
    lang: "SV"
  };

  componentDidMount() {
    this.fetchNoRepData();
  }
  changeLanguage(newLang, scrollPos) {
    if (this.state.lang !== newLang) {
      this.btn.style.display = "none";
      this.clearData();
      this.setState(
        {
          lang: newLang,
          sidebarOpen: false,
          scrollPos: scrollPos
        },
        () => {
          this.fetchNoRepData();
        }
      );
    } else {
      this.langErr.style.fontSize = "1em";
      setTimeout(() => {
        this.langErr.style.fontSize = "0";
      }, 4000);
    }
  }
  clearData() {
    this.setState({
      panButtons: [],
      blocks: [],
      homePageText: "",
      posterDates: [],
      homePage: [],
      posterHeader: "",
      posterText: "",
      posters: [],
      map: [],
      posterImages: []
    });
  }
  onSetSidebarOpen = () => {
    if (this.state.sidebarOpen) {
      this.setState({ sidebarOpen: false });
    } else {
      this.setState({ sidebarOpen: true });
    }
  };
  closeSidebar() {
    this.setState({ sidebarOpen: false });
  }
  async fetchNoRepData() {
    await fetch(
      this.state.lang === "SV"
        ? "http://head-tail.se/fmi/xml/fmresultset.xml?-db=web_database&-lay=web_norep&-findall"
        : this.state.lang === "EN"
        ? "http://head-tail.se/fmi/xml/fmresultset.xml?-db=web_database&-lay=web_norep_eng&-findall"
        : ""
    )
      .then(response => response.text())
      .then(rawData =>
        JSON.parse(
          require("xml-js").xml2json(rawData, { compact: true, spaces: 4 })
        )
      )
      .then(jsonData =>
        this.setNoRepData(jsonData.fmresultset.resultset.record)
      )
      .catch(error => {
        console.log(error);
        window.confirm(
          "We currently have some server issues\nBut you can reach us on +46 844 288 90 or hq@head-tail.se"
        );

        window.location.reload();
      });
  }

  setNoRepData(noRepData) {
    let homePageHeader = "HEAD & TAIL";
    let homePageText = noRepData.field[0].data._text;
    let videoUrl = noRepData.field[1].data._text;
    let videoId = videoUrl.substring(videoUrl.indexOf("?v=") + 3);
    let adress = noRepData.field[2].data._text;
    let email = noRepData.field[3].data._text;
    let phoneNumber = noRepData.field[4].data._text;
    let htlogo = "http://head-tail.se" + noRepData.field[5].data._text;
    let nrPosters = noRepData.field[6].data._text;
    let posterHeader = noRepData.field[7].data._text;
    let posterText = noRepData.field[8].data._text;
    let footerHeader = noRepData.field[9].data._text;
    let footerText = noRepData.field[10].data._text;
    let backgroundColor = noRepData.field[11].data._text;
    let headerBackground = noRepData.field[12].data._text;
    let headerColor = noRepData.field[13].data._text;
    let textColor = noRepData.field[14].data._text;

    this.setState({
      nrPosters: nrPosters,
      posterHeader: posterHeader,
      posterText: posterText,
      homePage: (
        <HomePage
          homePageHeader={homePageHeader}
          homePageText={homePageText}
          videoId={videoId}
        />
      ),
      map: (
        <Map
          lang={this.state.lang}
          adress={adress}
          email={email}
          phoneNumber={phoneNumber}
          htlogo={htlogo}
          endBlock={
            <EndBlock footerHeader={footerHeader} footerText={footerText} />
          }
        />
      ),
      textColor: textColor,
      headerColor: headerColor,
      backgroundColor: backgroundColor,
      headerBackground: headerBackground
    });
    this.fetchBlocks();
  }
  async fetchBlocks() {
    await fetch(
      this.state.lang === "SV"
        ? "http://head-tail.se/fmi/xml/fmresultset.xml?-db=web_database&-lay=web_blocks&-findall&-sortfield.1=block_order"
        : this.state.lang === "EN"
        ? "http://head-tail.se/fmi/xml/fmresultset.xml?-db=web_database&-lay=web_blocks_eng&-findall&-sortfield.1=block_order"
        : ""
    )
      .then(response => response.text())
      .then(rawData =>
        JSON.parse(
          require("xml-js").xml2json(rawData, { compact: true, spaces: 4 })
        )
      )
      .then(jsonData =>
        this.createBlockArray(jsonData.fmresultset.resultset.record)
      )
      .catch(error => {
        console.log(error);

        window.confirm(
          "We currently have some server issues\nYou can reach us on +46 844 288 90 or hq@head-tail.se"
        );

        window.location.reload();
      });
  }
  handlePanButtonClick(element) {
    this.setState({ panButtonClicked: true });
    this.closeSidebar();
    scrollToComponent(element, {
      offset: 0,
      align: "top",
      duration: 2500,
      ease: "outCube"
    });
  }

  createBlockArray(blockData) {
    var index = 0;
    blockData.forEach(element => {
      this.setState(previousState => ({
        blocks: [
          ...previousState.blocks,
          <section
            ref={section => {
              element = section;
            }}
          >
            <BlockMaker blockData={element} />
          </section>
        ]
      }));
      this.setState(previousState => ({
        panButtons: [
          ...previousState.panButtons,

          <button
            className="panButton"
            onClick={() => this.handlePanButtonClick(element)}
          >
            {blockData[index].field[3].data._text}
          </button>
        ]
      }));
      index++;
    });
    this.setState(previousState => ({
      panButtons: [
        ...previousState.panButtons,
        <button
          className="panButton"
          onClick={() => this.handlePanButtonClick(this.posters)}
        >
          {this.state.posterHeader}
        </button>
      ]
    }));
    this.setState(previousState => ({
      panButtons: [
        ...previousState.panButtons,

        <button
          className="panButton"
          onClick={() => this.handlePanButtonClick(this.contactUs)}
        >
          {this.state.lang === "SV"
            ? "Kontakta Oss"
            : this.state.lang === "EN"
            ? "Contact Us"
            : null}
        </button>
      ]
    }));
    this.fetchPosters();
    this.changeColor();
  }
  async fetchPosters() {
    await fetch(
      `http://head-tail.se/fmi/xml/fmresultset.xml?-db=web_database&-lay=web_posters&-max=${this.state.nrPosters}&-findall&-sortfield.1=random_num`
    )
      .then(response => response.text())
      .then(rawData =>
        JSON.parse(
          require("xml-js").xml2json(rawData, { compact: true, spaces: 4 })
        )
      )
      .then(jsonData =>
        this.makePosterArray(jsonData.fmresultset.resultset.record)
      )
      .catch(error => {
        console.log(error);
        window.confirm(
          "We currently have some server issues\nBut you can reach us on +46 844 288 90 or hq@head-tail.se"
        );
        window.location.reload();
      });
  }
  makePosterArray(posterData) {
    posterData.forEach(element => {
      let bigPoster = "http://head-tail.se" + element.field[1].data._text;
      let posterLink = element.field[3].data._text;
      let posterDate = element.field[4].data._text;
      this.setState(previousState => ({
        posterImages: [
          ...previousState.posterImages,
          <div className="poster2">
            <div className="poster">
              <img
                className="smallPoster"
                src={bigPoster}
                alt={`Releasedate: ${posterDate}`}
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="posterLink"
                href={posterLink}
              >
                <p className="posterDate">
                  {posterDate}
                  <br />
                  {this.state.lang === "SV"
                    ? "Läs Mer"
                    : this.state.lang === "EN"
                    ? "Read More"
                    : null}
                </p>
              </a>
            </div>
          </div>
        ]
      }));
    });
    this.setState({
      posters: (
        <SRLWrapper>
          <div className="posters">{this.state.posterImages}</div>
        </SRLWrapper>
      )
    });
    window.scrollTo(0, this.state.scrollPos);
    this.btn.style.display = "block";
  }
  changeColor() {
    var convert = require("color-convert");
    var headerGradiant;
    let hslArray = convert.hex.hsl(this.state.headerBackground);
    //let hslArray = convert.hex.hsl("#ffc0cb");
    let headerBackground =
      "hsla(" +
      hslArray[0] +
      ", " +
      hslArray[1] +
      "%, " +
      hslArray[2] +
      "%, 0.95)";
    if (hslArray[2] >= 50) {
      //make darker gradient
      headerGradiant =
        "hsl(" +
        hslArray[0] +
        ", " +
        hslArray[1] +
        "%, " +
        (hslArray[2] - 50) +
        "%)";
    } else {
      //make lighter gradient
      headerGradiant =
        "hsl(" +
        hslArray[0] +
        ", " +
        hslArray[1] +
        "%, " +
        (hslArray[2] + 50) +
        "%)";
    }
    let footerBackground =
      "hsl(" + hslArray[0] + ", " + hslArray[1] + "%, " + hslArray[2] + "%)";
    document.body.style.color = this.state.textColor;
    document.body.style.background = this.state.backgroundColor;
    let headerElement = document.getElementsByTagName("header");
    for (let i = 0; i < headerElement.length; i++) {
      headerElement[i].style.background =
        "linear-gradient(to right, " +
        headerGradiant +
        " -100%, " +
        headerBackground +
        " 50%, " +
        headerGradiant +
        " 200%)";

      headerElement[i].style.color = this.state.headerColor;
    }
    let footerElement = document.getElementsByTagName("footer");
    for (let i = 0; i < footerElement.length; i++) {
      footerElement[i].style.background =
        "linear-gradient(to right, " +
        headerGradiant +
        " -100%, " +
        footerBackground +
        " 50%, " +
        headerGradiant +
        " 200%)";
    }
    let sidebarElement = document.getElementsByClassName("mobileButtons");
    for (let i = 0; i < sidebarElement.length; i++) {
      sidebarElement[i].style.background =
        "linear-gradient(to bottom, " +
        headerGradiant +
        " -100%, " +
        footerBackground +
        "f2 50%, " +
        headerGradiant +
        " 200%)";
    }
  }
  render() {
    return (
      <div
        className="top-container"
        ref={section => {
          this.topSection = section;
        }}
      >
        <BrowserView>
          <div
            className="langContainer"
            ref={btn => {
              this.btn = btn;
            }}
          >
            <p
              ref={langErr => {
                this.langErr = langErr;
              }}
              className="langError"
            >
              {this.state.lang === "SV"
                ? `${this.state.lang} är redan aktiverat`
                : this.state.lang === "EN"
                ? `${this.state.lang} is already activated`
                : null}
            </p>

            <span className="langList">
              <button
                className="langButton"
                onClick={() => this.changeLanguage("SV", window.pageYOffset)}
              >
                <img className="langFlag" src={svFlag} alt=""></img>
                {" SV"}
              </button>

              <button
                className="langButton"
                onClick={() => this.changeLanguage("EN", window.pageYOffset)}
              >
                <img className="langFlag" src={enFlag} alt=""></img>
                {" EN"}
              </button>
            </span>
          </div>
          <div className="panButton-container">
            {this.state.panButtons.map((item, key) => {
              return (
                <span className="panButtons" key={key}>
                  {item}
                </span>
              );
            })}
          </div>
        </BrowserView>

        <MobileView className="mobileView">
          <Sidebar
            sidebar={
              <div className="mobileButtons">
                <div
                  className="mobileLangContainer"
                  ref={btn => {
                    this.btn = btn;
                  }}
                >
                  <p
                    ref={langErr => {
                      this.langErr = langErr;
                    }}
                    className="langError"
                  >
                    {this.state.lang === "SV"
                      ? `${this.state.lang} är redan aktiverat`
                      : this.state.lang === "EN"
                      ? `${this.state.lang} is already activated`
                      : null}
                  </p>
                  <div className="langList">
                    <button
                      className="langButton"
                      onClick={() =>
                        this.changeLanguage("SV", window.pageYOffset)
                      }
                    >
                      <img className="langFlag" src={svFlag} alt=""></img>
                      {" SV"}
                    </button>

                    <button
                      className="langButton"
                      onClick={() =>
                        this.changeLanguage("EN", window.pageYOffset)
                      }
                    >
                      <img className="langFlag" src={enFlag} alt=""></img>
                      {" EN"}
                    </button>
                  </div>
                </div>
                <button
                  className="panButton"
                  onClick={() => this.handlePanButtonClick(this.topSection)}
                >
                  {this.state.lang === "SV"
                    ? "Startsida"
                    : this.state.lang === "EN"
                    ? "Homepage"
                    : null}
                </button>
                {this.state.panButtons}
              </div>
            }
            pullRight={true}
            open={this.state.sidebarOpen}
            onSetOpen={this.onSetSidebarOpen}
            styles={{
              sidebar: {
                position: "fixed",
                height: "100vh",
                zIndex: "1000"
              }
            }}
          >
            <button
              className="sidebarButton"
              type="button"
              onClick={() => this.onSetSidebarOpen(true)}
            >
              <p className="sidebarButtonText">
                _<br />_<br />_<br />
              </p>
            </button>
          </Sidebar>
        </MobileView>

        {this.state.homePage}

        <BrowserView>
          <Rellax overflow="hidden" speed={scrollSpeed}>
            <img className="topLogo" src={topLogo} alt="" />
            <img className="bottomLogo" src={bottomLogo} alt="" />
          </Rellax>
        </BrowserView>

        <MobileView>
          <img className="topLogoMobile" src={topLogo} alt="" />
        </MobileView>

        {this.state.blocks.map((item, key) => {
          return <div key={key}>{item}</div>;
        })}
        <div className="container">
          <header
            className="header-container"
            ref={section => {
              this.posters = section;
            }}
          >
            <h1 className="header">{this.state.posterHeader}</h1>

            <p className="text">{this.state.posterText}</p>
            <br />
          </header>
          <div className="poster-container">
            <SimpleReactLightbox>{this.state.posters}</SimpleReactLightbox>
          </div>
        </div>
        <div
          className="container"
          ref={section => {
            this.contactUs = section;
          }}
        >
          {this.state.map}
        </div>
        <BrowserView>
          <ScrollUpButton
            ContainerClassName="ScrollUpButton__Container"
            TransitionClassName="ScrollUpButton__Toggled"
            AnimationDuration={1500}
            EasingType="easeOutCubic"
            StopPosition={0}
          >
            <span class="BTN">UP &#8594;</span>
          </ScrollUpButton>
        </BrowserView>
        <div className="curtains">
          <img className="curtain-top" src={curtainTop} alt="" />
          <img className="curtain-left" src={curtainLeft} alt="" />
          <img className="curtain-right" src={curtainRight} alt="" />
        </div>
      </div>
    );
  }
}
export default App;
