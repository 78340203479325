import React from "react";
import YoutubeBackground from "react-youtube-background";

const HomePage = props => {
  return (
    <div>
      {props.videoId.length > 0 ? (
        <YoutubeBackground videoId={props.videoId} className="video">
          <div className="homePage">
            <h1 className="header">
              <div class="box">
                <span data-char="HEAD & TAIL">HEAD & TAIL</span>
              </div>
            </h1>
            {props.homePageText > [0]
              ? props.homePageText.split("\r").map((item, key) => (
                  <span className="text" key={key}>
                    {item}
                    <br />
                  </span>
                ))
              : null}
          </div>
        </YoutubeBackground>
      ) : (
        <div className="homePage">
          <h1 className="header">{props.homePageHeader}</h1>
          {props.homePageText > [0]
            ? props.homePageText.split("\r").map((item, key) => (
                <span className="text" key={key}>
                  {item}
                  <br />
                </span>
              ))
            : null}
        </div>
      )}
    </div>
  );
};

export default HomePage;
