import React from "react";
import Reveal from "react-reveal/Fade";
import Button from "@material-ui/core/Button";
import config from "react-reveal/globals";
config({ ssrFadeout: true });
class BlockMaker extends React.Component {
  state = {
    image: [],
    button: []
  };

  componentDidMount() {
    this.setBlockData(this.props.blockData);
  }

  setBlockData(data) {
    let style = data.field[2].data._text;

    let header = data.field[3].data._text;

    let text = data.field[4].data._text;

    let subHeader = data.field[0].data._text;

    let subText = data.field[1].data._text;

    if (data.relatedset[0]._attributes.count === "1") {
      var image = data.relatedset[0].record.field.data._text;
      this.setState({
        image: [
          <img className="image" src={"http://head-tail.se" + image} alt="" />
        ]
      });
    } else {
      for (
        let index = 0;
        index < data.relatedset[0]._attributes.count;
        index++
      ) {
        let image = data.relatedset[0].record[index].field.data._text;
        this.setState(previousState => ({
          image: [
            ...previousState.image,
            <img className="image" src={"http://head-tail.se" + image} alt="" />
          ]
        }));
      }
    }

    if (data.relatedset[1]._attributes.count === "1") {
      let buttonText = data.relatedset[1].record.field[0].data._text;
      let buttonLink = data.relatedset[1].record.field[1].data._text;
      this.setState({
        button: [
          <Button
            className="button"
            target="_blank"
            variant="contained"
            href={buttonLink}
            shape="borderRadius:100"
          >
            {buttonText}
          </Button>
        ]
      });
    } else {
      for (
        let index = 0;
        index < data.relatedset[1]._attributes.count;
        index++
      ) {
        let buttonText = data.relatedset[1].record[index].field[0].data._text;
        let buttonLink = data.relatedset[1].record[index].field[1].data._text;
        this.setState(previousState => ({
          button: [
            ...previousState.image,
            <Button
              className="button"
              target="_blank"
              variant="contained"
              href={buttonLink}
              shape="borderRadius:100"
            >
              {buttonText}
            </Button>
          ]
        }));
      }
    }
    this.setState({
      style: style,
      header: header,
      text: text,
      subHeader: subHeader,
      subText: subText
    });
  }

  render() {
    return (
      <div className="container">
        <div className={this.state.style}>
          <Reveal bottom>
            <header className="header-container">
              <h1 className="header">{this.state.header}</h1>
              {this.state.text > [0]
                ? this.state.text.split("\r").map((item, key) => (
                    <span classname="span" key={key}>
                      <p className="text">{item}</p>
                      <br />
                    </span>
                  ))
                : null}
            </header>
          </Reveal>
          <div className="text-image-container">
            <Reveal bottom>
              <div className="subHeader-container">
                <h3 className="header">{this.state.subHeader}</h3>
                <div className="textbox">
                  {this.state.subText > [0]
                    ? this.state.subText.split("\r").map((item, key) => (
                        <span className="subText" key={key}>
                          {item}
                          <br />
                        </span>
                      ))
                    : null}
                </div>
              </div>
            </Reveal>
            {this.state.image > [0] ? (
              <div className="image-container">
                {this.state.image.map((item, key) => (
                  <div className="image" key={key}>
                    {item}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <Reveal bottom>
            <div>
              {this.state.button > [0] ? (
                <div className="button-container">
                  {this.state.button.map((item, key) => (
                    <div key={key}>{item}</div>
                  ))}
                </div>
              ) : null}
            </div>
          </Reveal>
        </div>
      </div>
    );
  }
}

export default BlockMaker;
