import React from "react";

const EndBlock = props => {
  return (
    <footer className="endBlock">
      <h1 className="header">{props.footerHeader}</h1>

      {props.footerText > [0]
        ? props.footerText.split("\r").map((item, key) => (
            <span className="text" key={key}>
              {item}
              <br />
            </span>
          ))
        : null}
    </footer>
  );
};

export default EndBlock;
